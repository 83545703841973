import React, { useEffect } from 'react';

const RedirectComponent = () => {
  useEffect(() => {
    window.location.href = "https://www.instagram.com/henryklimes/";
  }, []);

  return null; // Optionally return null since no UI is shown
};

export default RedirectComponent;