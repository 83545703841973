import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; 
import Home from "./Home.js";
import Projects from "./Projects.js";
import Instagram from "./Instagram.js";
import Gott from "./Gott.js";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="content">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/projects">
              <Projects />
            </Route>
            <Route path="/instagram">
              <Instagram></Instagram>
            </Route>
            <Route path="/gott">
              <Gott></Gott>
            </Route>
            <Route path="*">
              <Home></Home>
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
