import React, { useEffect } from 'react';

const RedirectComponent = () => {
  useEffect(() => {
    window.location.href = "https://www.youtube.com/watch?v=RxLV8xM51kU";
  }, []);

  return null; // Optionally return null since no UI is shown
};

export default RedirectComponent;